import React from 'react';
import Link from 'next/link';
import styles from '../styles.module.css';
import Image from 'next/image';
import LaunchStatus from '../../../launch-status';

interface ChipProps {
    index?: number;
    name?: string;
    displayImage?: string;
    username?: string;
    tagline?: string;
    launchStatus?: 'live-now' | 'live-soon' | 'coming-soon';
}

export const InfluencerChip = ({
    index,
    displayImage = '/assets/img/cel-profile-img.png',
    name = 'Creator Name',
    username = 'creatorUsername',
    tagline = 'Creator',
    launchStatus = 'live-now',
    ...props
}: ChipProps) => {
    return (
        <div {...props} className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <Link href={'/[username]'} as={`/${username}`}>
                <div
                    data-attr-username={username}
                    className={`${styles.animateWrapper} mb-5 d-flex flex-column flex-md-row cursor-pointer justify-content-start align-items-center shadow-sm`}
                >
                    <div className={styles.imageContainer}>
                        <Image src={displayImage} width={100} height={100} />
                    </div>
                    <div
                        className={`${styles.infoContainer} text-center text-md-start`}
                    >
                        <p className={styles.name}>{name}</p>

                        <p className={styles.desc}>{tagline}</p>

                        <div
                            className={`${styles.status} d-flex justify-content-center justify-content-md-start`}
                        >
                            <LaunchStatus status={launchStatus} />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};
