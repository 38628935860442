import React from 'react';
import { BottomBanner } from './bottomBanner';
import { HowItWorks } from './how-it-works';
import { DontMissOut } from './dontMissOut';
import { MiddleBanner } from './middle-banner';
import { OnFire } from './onFire';
import TopBanner from './topBanner';
import { TrendingInfluencer } from './trendingInfluencer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

export default function LandingPageInfo({
    onFire,
    dontMissOut,
    creators,
    topBannerDesc,
    session,
}) {
    const reduxSession = useSelector((state: RootState) => state.session);

    return (
        <>
            <div>
                <div>
                    <TopBanner
                        topBannerDesc={topBannerDesc}
                        isAuthenticated={session?.isAuthenticated}
                        displayName={reduxSession?.name ?? session?.name}
                    />
                </div>
                <div style={{ marginBottom: '80px' }}>
                    <MiddleBanner />
                </div>
                <div className="container">
                    <TrendingInfluencer influencers={creators} />

                    <div style={{ marginTop: '96px', marginBottom: '148px' }}>
                        <OnFire products={onFire} />
                    </div>
                </div>

                <div className="container">
                    {/* <div style={{ marginBottom: '148px' }}>
                        <DontMissOut products={dontMissOut} />
                    </div> */}

                    <div style={{ marginBottom: '110px' }}>
                        <HowItWorks
                            isAuthenticated={session?.isAuthenticated}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
