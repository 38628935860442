import Image from 'next/image';
import React from 'react';
import topBannerStyles from './topBanner.module.css';

const TopBanner = ({
    isAuthenticated = false,
    displayName = 'Guest User',
    topBannerDesc,
}) => {
    return (
        <>
            {isAuthenticated ? (
                <div className={`${topBannerStyles.gradientBack}`}>
                    <div
                        style={{ minHeight: '200px', padding: '40px 23px' }}
                        className="container d-flex align-items-center"
                    >
                        <div>
                            <h2
                                className={`${topBannerStyles.userGreetings} text-white`}
                            >
                                Hello,{' '}
                                <span
                                    style={{
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {displayName.split(' ')[0] ?? 'superfan'}
                                </span>
                            </h2>
                            <p
                                style={{
                                    wordBreak: 'break-word',
                                }}
                                className="font-18 text-white"
                            >
                                Browse experiences with your favorite creators
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={`${topBannerStyles.mainDiv} d-flex justify-content-center`}
                >
                    <div className="d-flex container align-items-end">
                        <div
                            className={`align-self-center ${topBannerStyles.headingDiv} my-5`}
                        >
                            <h3
                                className={`${topBannerStyles.headingFont} color-white m-0`}
                            >
                                {topBannerDesc}
                            </h3>
                        </div>
                        <div
                            className={`${topBannerStyles.animateImageDiv} d-lg-block ${topBannerStyles.picDiv} ${topBannerStyles.hidePicDiv} ${topBannerStyles.nextImage}`}
                        >
                            <Image
                                priority
                                layout="fixed"
                                width="550"
                                height="481"
                                src="/images/img-app-hero.svg"
                            />
                        </div>
                        <div
                            className={`d-block ${topBannerStyles.mPicDiv} ${topBannerStyles.hideMPicDiv} ${topBannerStyles.mNextImage}`}
                        >
                            <Image
                                priority
                                layout="fixed"
                                width="156"
                                height="246"
                                src="/images/m-img-app-hero.svg"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TopBanner;
