function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                zIndex: 101,
                position: 'absolute',
                right: 10,
                top: '60%',
            }}
            onClick={onClick}
        >
            <img
                style={{
                    width: '56px',
                    height: '56px',
                    boxShadow: ' 0px 6px 20px 12px rgba(0, 0, 0, 0.08)',
                    borderRadius: '35px',
                }}
                src="/assets/icon/slickRight.svg"
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                zIndex: 101,
                left: -25,
                position: 'absolute',
                top: '60%',
            }}
            onClick={onClick}
        >
            <div>
                <img
                    style={{
                        width: '56px',
                        height: '56px',
                        boxShadow: ' 0px 6px 20px 12px rgba(0, 0, 0, 0.08)',
                        borderRadius: '35px',
                    }}
                    src="/assets/icon/slickLeft.svg"
                />
            </div>
        </div>
    );
}

function NextArrowfor1200(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                zIndex: 101,
                position: 'absolute',
                marginTop: '30px',
                right: 30,
                top: '55%',
            }}
            onClick={onClick}
        >
            <img
                style={{
                    width: '56px',
                    height: '56px',
                    boxShadow: ' 0px 6px 20px 12px rgba(0, 0, 0, 0.08)',
                    borderRadius: '35px',
                }}
                src="/assets/icon/slickRight.svg"
            />
        </div>
    );
}

function PrevArrowfor1200(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                zIndex: 101,
                left: -5,
                marginTop: '30px',
                position: 'absolute',
                top: '55%',
            }}
            onClick={onClick}
        >
            <img
                style={{
                    width: '56px',
                    height: '56px',
                    boxShadow: ' 0px 6px 20px 12px rgba(0, 0, 0, 0.08)',
                    borderRadius: '35px',
                }}
                src="/assets/icon/slickLeft.svg"
            />
        </div>
    );
}
export const SliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                nextArrow: <NextArrowfor1200 />,
                prevArrow: <PrevArrowfor1200 />,
                dots: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                nextArrow: false,
                prevArrow: false,
                autoplay: true,
            },
        },
        {
            breakpoint: 790,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: false,
                prevArrow: false,
                autoplay: false,
                arrows: false,
            },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ],
};
