import Link from 'next/link';
import React from 'react';
import styles from './styles.module.css';

export const HowItWorks = ({ isAuthenticated = false }) => {
    return (
        <>
            <div>
                <div className="d-flex justify-content-center">
                    <div className={`my-5 text-center ${styles.headingDiv}`}>
                        {/* <span className={styles.forCreator}>For Creators</span> */}
                        <h5
                            id="how-it-works"
                            className={`color-black font_mob24_Lap_32`}
                        >
                            How it works?
                        </h5>
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-wrap align-items-center">
                    <div className={`${styles.CardDiv} m-3`}>
                        <img
                            className="w-100"
                            src="/images/creatorSignup/createAnNft.svg"
                        />
                        <p className="m-0 px-2 mt-4 color-black font-18 text-center">
                            Get in touch with us
                        </p>
                    </div>

                    <div className={`${styles.CardDiv} m-3`}>
                        <img
                            className="w-100"
                            src="/images/creatorSignup/superFanWillLove.svg"
                        />
                        <p className="m-0 px-2 mt-4 color-black font-18 text-center">
                            Share your ideas
                        </p>
                    </div>

                    <div className={`${styles.CardDiv}  m-3`}>
                        <img
                            className="w-100"
                            src="/images/creatorSignup/earnCrypto.svg"
                        />
                        <p className="m-0 px-2 mt-4 color-black font-18 text-center">
                            We launch you in the Metaverse
                        </p>
                    </div>
                </div>
                {!isAuthenticated && (
                    <div className="d-flex justify-content-center mt-5">
                        <Link href="/accounts/login">
                            <button
                                className={`bg-transparent color-pink font-16-bold-500 px-5 py-3 br-50 ${styles.buttonBorder}`}
                            >
                                Creator sign up
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};
