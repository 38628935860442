import Link from 'next/link';
import React from 'react';
import styles from './styles.module.css';

export const MiddleBanner = () => {
    return (
        <>
            <div className={``}>
                <div className={`${styles.mainDiv}`}>
                    <div className="my-3 m-0 d-flex px-3 justify-content-center">
                        <div className="text-center">
                            <div className={styles.headingDiv}>
                                <h4 className="font-41 color-black m-0">
                                    An end-to-end concierge service for the
                                    Metaverse
                                </h4>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className={styles.paragDiv}>
                                    <p className="color-black font-20 my-3">
                                        Launch your digital twin on social
                                        media, release virtual items and skins,
                                        drop utility NFTs or hold Metaverse
                                        events!
                                    </p>
                                    <Link href="#how-it-works">
                                        <p className="m-0 color-pink font-16-bold-500 cursor-pointer">
                                            How it works?
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flexjustify-content-between">
                        <div
                            className={`${styles.femaleIcon} d-none d-md-flex`}
                        >
                            <img width="200" src="/images/hwFemale.svg" />
                        </div>
                        <div className={`d-none d-md-flex ${styles.MaleIcon}`}>
                            <img width="200" src="/images/hwMale.svg" />
                        </div>
                        <div
                            className={`${styles.mfemaleIcon} d-flex d-md-none`}
                        >
                            <img width="150" src="/images/hwmFemale.svg" />
                        </div>
                        <div className={`d-flex d-md-none ${styles.mMaleIcon}`}>
                            <img width="150" src="/images/hwmMale.svg" />
                        </div>
                    </div>

                    <div className="d-none d-sm-block">
                        <div className={styles.pinkItem}>
                            <img src="/images/howitworkIcon/pnikline.svg" />
                        </div>
                        <div className={styles.purpleItem}>
                            <img src="/images/howitworkIcon/purpleLeaf.svg" />
                        </div>
                        <div className={styles.oranmgeItem}>
                            <img src="/images/howitworkIcon/orangeLine.svg" />
                        </div>
                        <div className={styles.greenItem}>
                            <img src="/images/howitworkIcon/greenLeaf.svg" />
                        </div>
                        <div className={styles.pinkLeaf}>
                            <img src="/images/howitworkIcon/pinkLeaf.svg" />
                        </div>
                        <div className={styles.greenDot}>
                            <img src="/images/howitworkIcon/orangeDot.svg" />
                        </div>
                    </div>

                    {/* display Items in md>>>>>>>>>>>>>>>>>>>>> */}
                    <div className="d-block d-sm-none">
                        <div className={`px-1 ${styles.mgreenTItem}`}>
                            <img
                                style={{ width: '100%' }}
                                src="/images/howitworkIcon/mobileview/element1.svg"
                            />
                        </div>

                        {/* <div className={howWeWorkStyles.mpinkTItem}>
                            <img src="/images/howitworkIcon/mobileview/pinktopLine.svg" />
                        </div>
                        <div className={howWeWorkStyles.mgreenLeaf}>
                            <img src="/images/howitworkIcon/mobileview/greenTopLeaf.svg" />
                        </div>
                        <div className={howWeWorkStyles.mpinkMidItem}>
                            <img src="/images/howitworkIcon/mobileview/pinktopLine.svg" />
                        </div>
                        <div className={howWeWorkStyles.mgreenMiditem}>
                            <img src="/images/howitworkIcon/mobileview/greenLine.svg" />
                        </div> */}
                        {/* <div className={howWeWorkStyles.morangeItem}>
                            <img src="/images/howitworkIcon/mobileview/orangeMidLine.svg" />
                        </div> */}

                        {/* ssssssssssssssss */}
                        <div className={styles.morangeMidnDot}>
                            <img src="/images/howitworkIcon/mobileview/orangeLeaf.svg" />
                        </div>
                        <div className={styles.mPurbleBLeaf}>
                            <img src="/images/howitworkIcon/mobileview/greenBLeaf.svg" />
                        </div>
                        <div className={styles.mgreenBLeaf}>
                            <img src="/images/howitworkIcon/mobileview/purpleLeaf.svg" />
                        </div>
                        <div className={styles.mpurpleBLeaf}>
                            <img src="/images/howitworkIcon/mobileview/pinkBLeaf.svg" />
                        </div>
                    </div>
                    {/* display Items in md>>>>>>>>>>>>>>>>>>>>> */}
                </div>
            </div>
        </>
    );
};
