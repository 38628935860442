import React from 'react';
import { H5 } from '../../styled-typography';
import { InfluencerChip } from './infulencerChip';

export const TrendingInfluencer = ({ influencers }) => {
    if (!influencers || !influencers.length) return <></>; // hide the section if no influencers found

    return (
        <>
            <div style={{ marginTop: '56px' }} className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: '40px' }}>
                        <H5>Creators</H5>
                    </div>
                </div>
            </div>

            <div className="row">
                {influencers?.map((data, index) => (
                    <InfluencerChip
                        index={index}
                        displayImage={data.displayImage}
                        name={data.name}
                        launchStatus={data.launchStatus}
                        username={data.username}
                        tagline={data.tagline}
                        key={index}
                    />
                ))}
            </div>
        </>
    );
};
