import { NFTCard } from '../../nft-card';
import SlickSlider from '../../sliders/slick-slider';
import { H5 } from '../../styled-typography';

export const OnFire = ({ products }) => {
    return (
        <>
            <div
                style={{ marginBottom: '40px' }}
                className="d-flex  align-items-center"
            >
                <H5>On fire</H5>
                <div className="mx-2 mb-2">
                    <img src="/assets/icon/fire.svg" />
                </div>
            </div>
            <SlickSlider
                customSettings={{
                    infinite: products?.length > 3,
                }}
            >
                {products?.map((prod) => (
                    <div
                        className="d-flex justify-content-center"
                        key={prod._id}
                    >
                        <NFTCard
                            _id={prod._id}
                            description={
                                prod.shortDescription ?? prod.description
                            }
                            title={prod.title}
                            category={prod.category.title}
                            creator={prod.creator}
                            auctionStartTime={prod.auctionStartTime}
                            gradientBorder={true}
                            showCreatorInfo={true}
                            mediaUrl={prod.mediaUrl}
                            narrowCardContainer={false}
                        />
                    </div>
                ))}
            </SlickSlider>
        </>
    );
};
