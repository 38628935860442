import React from 'react';
import Image from 'next/image';
import { ILaunchStatus } from '../../interfaces/ILaunchStatus';
import styles from './styles.module.css';

const LaunchStatus = ({ status }: ILaunchStatus) => {
    return (
        <>
            <div className={styles.statusContainer}>
                <span className={styles.icon}>
                    <Image
                        src={`/assets/icon/creator-${status}.svg`}
                        width={20}
                        height={20}
                    ></Image>
                </span>
                <span className={`${styles.text} ${styles[status]}`}>
                    {status == 'live-now' && 'Live Now'}
                    {status == 'live-soon' && 'Live Soon'}
                    {status == 'coming-soon' && 'Coming Soon'}
                </span>
            </div>
        </>
    );
};

export default LaunchStatus;
