import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SliderSettings } from '../../../utils/slider-settings';
import { CustomSettings } from '../../../types/carousel.d';

type Props = {
    customSettings?: CustomSettings;
};

const SlickSlider: React.FC<Props> = ({ children, customSettings }) => {
    const settings = { ...SliderSettings, ...customSettings };
    return <Slider {...settings}>{children}</Slider>;
};

export default SlickSlider;
